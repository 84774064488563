import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Card,
  Chip,
  Button,
} from "@mui/material";
import { getFileAsBlob } from "../../utils/api";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import DownloadIcon from "@mui/icons-material/Download";
import HelpIcon from "@mui/icons-material/Help";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { documentStatusFilter } from "../../utils/constants";
import VerifiedIcon from "@mui/icons-material/Verified";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { Row } from "./Scaffolds";
const FilePreview = ({ fileUrl, title, document, verifyCallback }) => {
  const [fileSrc, setFileSrc] = useState("");
  const previewStyle = {
    borderRadius: 8,
    width: "200px",
    height: "150px",
    textAlign: "left",
    border: "1px solid #ccc",
  };

  const fetchFile = useCallback(
    async (url) => {
      try {
        const fileSrc = await getFileAsBlob(fileUrl);
        setFileSrc(fileSrc);
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    },
    [fileUrl]
  );

  const handleDownload = () => {
    const link = window.document.createElement("a");
    link.href = fileSrc;
    link.download = fileUrl ? fileUrl.split("/").pop() : "downloaded-file";
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  useEffect(() => {
    if (
      !fileUrl.endsWith("undefined") &&
      !fileUrl.endsWith("null") &&
      !fileUrl.endsWith("=")
    ) {
      fetchFile(fileUrl);
    }
  }, [fileUrl, fetchFile]);
  const shoudShowErrorMessage =
    (document?.verified_status === "manual_required" ||
      document?.verified_status === "rejected" ||
      document?.verified_status === "failed") &&
    document?.message;
  return fileSrc &&
    !fileUrl.endsWith("undefined") &&
    !fileUrl.endsWith("null") &&
    !fileUrl.endsWith("=") ? (
    <Grid item xs={12}>
      <Card
        sx={{
          padding: 2,
        }}
      >
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography sx={{
              fontSize: 16,
              fontWeight: 600,
            }}>{title}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton
              color="info"
              aria-label="download"
              onClick={handleDownload}
              sx={{ cursor: "pointer" }}
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
        {document && (
          <div>
            <DocumentStatusTag status={document.verified_status} />
            {shoudShowErrorMessage && (
              <Row
                sx={{
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <Chip
                  color="error"
                  sx={{
                    mb: 1,
                  }}
                  size="small"
                  label={document.message}
                  icon={<ErrorIcon sx={{ fill: "white" }} />}
                ></Chip>
               {document.verified_status === 'manual_required' && document.document_type === 'qualification' && <Button
                  variant="contained"
                  sx={{
                    borderRadius: 20,
                    mb: 1,
                  }}
                  size="small"
                  color="primary"
                  onClick={() => verifyCallback(fileSrc)}
                >
                  Verify
                </Button>}
              </Row>
            )}
          </div>
        )}
        {fileUrl?.endsWith("pdf") ? (
          <Worker workerUrl="/pdf.worker.min.js">
            <Viewer fileUrl={fileSrc} initialPage={0} />
          </Worker>
        ) : (
          <img src={fileSrc} alt={title} style={previewStyle} />
        )}
      </Card>
    </Grid>
  ) : null;
};

function DocumentStatusTag({ status }) {
  const statusColor = {
    manual_required: "#FFC300",
    success: "green",
    failed: "#E4252E",
    rejected : "#E4252E",
    verifying: "#ed6c02",
  };
  return (
    <Chip
      label={documentStatusFilter.find((item) => item.id === status)?.name}
      sx={{
        backgroundColor: statusColor[status],
        color: "white",
        mb: 1,
      }}
      icon={
        status === "success" ? (
          <VerifiedIcon sx={{ fill: "white" }} />
        ) : status === "manual_required" ? (
          <HelpIcon sx={{ fill: "white" }} />
        ) : status === "verifying" ? (
          <AccessTimeFilledIcon sx={{ fill: "white" }} />
        ) : status === "failed" && status === "rejected" ? (
          <CancelIcon sx={{ fill: "white" }} />
        ) : null
      }
      size="small"
    ></Chip>
  );
}
export default FilePreview;
