import React, { useState, useEffect, useCallback } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Stack, Typography, Chip } from "@mui/material";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import { get, getFileAsBlob } from "../../../../../utils/api";
import { BackButton } from "../../../../reusable/Buttons";
import FilePreview from "../../../../reusable/FilePreview";
import Video from "../../../../reusable/video";
import { formatTimestampToddmmyyyy } from "../../../../../utils/time";
import { CustomAvatar } from "../../../../Layout";
import {
  stringAvatar,
  extractSuburb,
  extractState,
} from "../../../../../utils/helper_functions";
import { ManualVerifyModal } from "./manual_verify_popup";

const MemberDetail = () => {
  const { member_id } = useParams();
  const [member, setMember] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentManualDocument, setCurrentManualDocument] = useState(null);
  const [currentFileUrl, setCurrentFileUrl] = useState(null);
  const [manualModalOpen, setManualModalOpen] = useState(false);
  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();
  const baseUrl = "/file-content?filePath=";
  const handleBackButton = () => {
    navigate("/applications");
  };

  const profileDetailStyles = {
    label: {
      width: 150,
      fontWeight: "bold",
      textAlign: "right",
      marginRight: "10px",
      fontSize: "13px",
    },
    value: { marginLeft: "10px", fontSize: "13px" },
    container: { display: "flex" },
  };

  const openToMap = {
    fullTime: "Full time",
    partTime: "Part time",
    casual: "Casual",
    childMinding: "Child Minding",
  };
  const ageGroupMap = {
    "0_24_months": " 0 - 24 Months",
    "2_3_years": "2 - 3 Years",
    "4_6_years": "3 - 6 Years",
    oosh: "OOSH",
  };
  const noticePeriodMap = {
    "0_2_weeks": "0 - 2 Weeks",
    "2_4_weeks": "2 - 4 Weeks",
    "4_plus_weeks": "4+ Weeks",
  };

  const getOpenTo = (openTo) => {
    return openTo
      ?.map((item) => (openToMap[item] ? openToMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getAgeGroup = (ageGroup) => {
    return ageGroup
      ?.map((item) => (ageGroupMap[item] ? ageGroupMap[item] : ""))
      .filter((item) => item !== "")
      .join(", ");
  };

  const getNoticePeriod = (noticePeriod) => {
    return noticePeriodMap[noticePeriod] ? noticePeriodMap[noticePeriod] : "";
  };

  const loadMemberIdentityLink = useCallback(async () => {
    try {
      const identityLink = await get(`/admin/onboarding/identity/${member_id}`);
      console.log("identityLink:", identityLink);
      return identityLink;
    } catch (error) {
      return null;
    }
  }, [member_id]);

  const loadMember = useCallback(async () => {
    try {
      const response = await get(`/admin/onboarding/${member_id}`);
      let avatar = null;
      if (response.photo_url) {
        avatar = await getFileAsBlob(`${baseUrl}${response.photo_url}`);
      }
      const identityLink = await loadMemberIdentityLink();
      setMember({ ...response, avatar, identityLink });
    } catch (error) {
      console.error("Failed to fetch members:", error);
    }
  }, [loadMemberIdentityLink, member_id]);

  const getWWCCData = (wwcc_data) => {
    if (wwcc_data && Array.isArray(wwcc_data)) {
      return wwcc_data
        .map(entry => `${entry.wwcc_number} - ${entry.state}`)
        .join(',  ');
    }
    return '';
  }

  useEffect(() => {
    loadMember();
  }, [loadMember]);
  const memberQualification = {...member.documents?.find(
    (doc) => doc.document_type === "qualification"
  ), person_id: member.person_id};
  const memberResume = member.documents?.find(
    (doc) => doc.document_type === "resume"
  );
  const memberCPR = member.documents?.find(
    (doc) => doc.document_type === "cpr_cert"
  );
  const memberFirstAid = member.documents?.find(
    (doc) => doc.document_type === "first_aid_cert"
  );
  const transcriptUrl = member.documents?.find(
    (doc) => doc.document_type === "transcript_url"
  );
  return (
    <>
      <ManualVerifyModal
        open={manualModalOpen}
        onClose={() => {
          loadMember();
          setManualModalOpen(false)
        }} 
        document={currentManualDocument}
        personID={member.person_id}
        fileUrl={currentFileUrl}
        fileSrc={selectedFileSrc}
      />
      <Box sx={{ mx: "auto", p: 3, marginTop: "-50px" }}>
        <BackButton
          title="Back to Members"
          onClick={handleBackButton}
        ></BackButton>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <CustomAvatar
              {...stringAvatar(member.first_name + " " + member.last_name)}
              src={member.avatar !== null ? member.avatar : null}
              sx={{ width: 120, height: 120, mx: "auto", borderRadius: "50%" }}
            />
            <Button
              variant="contained"
              onClick={openModal}
              startIcon={<VideoCameraFrontIcon />}
              disabled={!member.video_url}
              sx={{ mt: 2, backgroundColor: "#7A4FFF" }}
            >
              Short video
            </Button>
            <Video
              videoUrl={member.video_url}
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack spacing={1}>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Rego State:
                </Typography>
                <Chip
                  label={
                    member.step === "added_wwwc" ? "Complete" : "Verifying"
                  }
                  size="small"
                  sx={{
                    backgroundColor:
                      member.step === "added_wwwc" ? "#93dcce" : "#ed6c02",
                  }}
                />
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>Name:</Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.prefered_name}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>Suburb:</Typography>
                <Typography sx={profileDetailStyles.value}>{`${extractSuburb(
                  member.address
                )} ${extractState(member.address)}`}</Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>Email:</Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.email}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Phone Number:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.phone_number}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Date of birth:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {formatTimestampToddmmyyyy(member.date_of_birth)}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Australian Citizen:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.is_aus_citizen ? "Yes" : "No"}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>Open to:</Typography>
                <Typography sx={profileDetailStyles.value}>
                  {getOpenTo(member.open_to)}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Preferred age groups:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {getAgeGroup(member.age_group)}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Notice period:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {getNoticePeriod(member.notice_period)}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  Distance willing to work:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.distance}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  WWCC Number:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {getWWCCData(member.wwcc_data)}
                </Typography>
              </Box>
              <Box sx={profileDetailStyles.container}>
                <Typography sx={profileDetailStyles.label}>
                  VEVO check:
                </Typography>
                <Typography sx={profileDetailStyles.value}>
                  {member.vevo_check}
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <hr
            style={{
              width: "100%",
              border: "1px solid #ccc",
              margin: "20px 0",
            }}
          />
          <Grid spacing={2} item xs={3} container>
            <Grid container spacing={2} item xs={12}>
              <Typography variant="h6" gutterBottom>
                Photo ID
              </Typography>
            </Grid>
            <Grid container spacing={2} item xs={6}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Front</Typography>
                {member.identityLink && (
                  <img
                    src={member.identityLink}
                    alt={"Front"}
                    style={{
                      borderRadius: 8,
                      width: "200px",
                      height: "150px",
                      textAlign: "left",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} item xs={9}>
            <Grid container spacing={2} item xs={12}>
              <Typography variant="h6" gutterBottom>
                Qualification
              </Typography>
            </Grid>

            <Grid container spacing={2} item xs={6}>
              <FilePreview
                fileUrl={`${baseUrl}${member.qualification_url}`}
                title="Qualification"
                document={memberQualification}
                verifyCallback={(fileSrc) => {
                  setSelectedFileSrc(fileSrc);
                  setCurrentManualDocument(memberQualification);
                  setCurrentFileUrl(`${baseUrl}${member.qualification_url}`);
                  setManualModalOpen(true);
                }}
              />
              <FilePreview
                fileUrl={`${baseUrl}${member.resume_url}`}
                title="Resume / CV"
                document={memberResume}
              />
              <FilePreview
                fileUrl={`${baseUrl}${member.transcript_url}`}
                title="Transcript"
                document={transcriptUrl}
              />
            </Grid>
            <Grid container spacing={2} item xs={6}>
              <FilePreview
                fileUrl={`${baseUrl}${member.cpr_cert_url}`}
                title="CPR Certificate"
                document={memberCPR}
              />
              <FilePreview
                fileUrl={`${baseUrl}${member.first_aid_cert}`}
                title="First Aid"
                document={memberFirstAid}
              />
              <FilePreview
                fileUrl={`${baseUrl}${member.child_protection_cert}`}
                title="Child Protection"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default MemberDetail;
