import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { get, post, postFormData } from "../../../../../utils/api";
import { Row } from "../../../../Layout";
import theme from "../../../../../utils/theme";
import { ReactComponent as Cert3Logo } from '../../../../../assets/cert3-logo.svg';
import { ReactComponent as DiplomaLogo } from '../../../../../assets/diploma-logo.svg';
import { ReactComponent as EctLogo } from '../../../../../assets/ect-logo.svg';
import {
  CommonTextField,
  DatePickerTextField,
} from "../../../../reusable/TextField";
import { PaddingBox } from "../../../../reusable/Scaffolds";
import { qualificationLevelId } from "../../../../../utils/constants";
export function ManualVerifyModal({
  open,
  onClose,
  document,
  fileSrc,
  fileUrl,
  personID,
}) {
  const fileNameInput = fileUrl?.split("/").pop();
  const [updatedFile, setUpdatedFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [fileName, setFileName] = useState(fileNameInput);
  const defaultDate = new Date();
  const defaultDateStr = `${defaultDate.getFullYear()}-${defaultDate.getMonth()}-${defaultDate.getDate()}`;
  const [reason, setReason] = useState("");
  const [documentDate, setDocumentDate] = useState(defaultDateStr);
  const [selectedQualificationId, setSelectedQualificationId] = useState(null);
  const [passedMilestones, setPassedMilestones] = useState(null);
  const [qualificationsList, setQualificationsList] = useState([]);
  const fileInputRef = useRef(null);
  const previewStyle = {
    borderRadius: 8,
    width: "150px",
    height: "100px",
    textAlign: "left",
    border: "1px solid #ccc",
  };
  const closeAndReset = () => {
    onClose();
    setDocumentDate("");
    setPassedMilestones(false);
  };

  const rejectQualification = async () => {
    try {
      await post("/admin/qualification/manual_rejection", {
        document_id: document?.id,
        reason,
      });
      closeAndReset();
    } catch (error) {
      console.error(error);
    }
  };
  const getUserQualification = useCallback(async () => {
    try {
      if (!personID) {
        return;
      }
      const res = await get("/admin/qualification", null, {
        educator_id: personID,
      });
      setPassedMilestones(res?.milestone_passed);
      setDocumentDate(res?.latest_qualification_date);
      qualificationsList.map((qualification) => {
        if (res.qualification === qualification?.id) {
          qualification.selected = true;
          setSelectedQualificationId(res.qualification);
        }
        return qualification;
      });
      setQualificationsList(qualificationsList);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personID]);
  const updateQualificationFile = async (updatedFile, fileName) => {
    if (!updatedFile) {
      return;
    }
    setUploadingFile(true);
    const formData = new FormData();
    formData.append("file", updatedFile);
    const fileType = updatedFile.type;
    try {
      await postFormData(
        `/admin/qualification/upload?educator_id=${personID}&file_name=${fileName}&file_type=${fileType}`,
        formData,
        {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        }
      );
    } catch (error) {}
    setUploadingFile(false);
  };
  const fetchQualifications = async () => {
    try {
      const orgData = await get("/manage/qualifications", null, {
        is_profile: true,
      });
      const qualificationMapped = orgData.qualifications
        .filter(
          (item) =>
            item.id !== qualificationLevelId.ANY &&
            item.id !== qualificationLevelId.UNQUALIFIED
        )
        .map((qualification) => {
          qualification.value = qualification.id;
          qualification.name = qualification.qualification;
          // replace Working towards with WT
          qualification.name = qualification.name.replace(
            "Working towards",
            "WT"
          );
          return qualification;
        });
      // Re aranage the qualification list in order of item with id % 2 === 0 will be shown later in the list

      qualificationMapped.sort((a, b) => {
        if (a.id % 2 === 0 && b.id % 2 !== 0) {
          return 1;
        } else if (a.id % 2 !== 0 && b.id % 2 === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      setQualificationsList(qualificationMapped);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setUpdatedFile(file);
    setFileName(file.name);
    updateQualificationFile(file, file.name);
  };
  const verifyQualification = async () => {
    try {
      await post("/admin/qualification/manual_verification", {
        qualification_id: selectedQualificationId,
        enrollment_date: documentDate,
        milestone_passed: passedMilestones,
        educator_id: document?.person_id,
      });
      closeAndReset();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (fileNameInput) {
      setFileName(fileNameInput);
    }
  }, [fileNameInput]);

  useEffect(() => {
    fetchQualifications();
  }, []);

  useEffect(() => {
    getUserQualification();
  }, [getUserQualification]);

  const getLogoComponent = (qualification, color) => {
    if (
      qualification.value === qualificationLevelId.CERT3 ||
      qualification.value === qualificationLevelId.CERT_TOWARD
    ) {
      return <Cert3Logo style={{ fill: color, width: "50px", height: "50px" }} />;
    } else if (
      qualification.value === qualificationLevelId.DIPLOMA ||
      qualification.value === qualificationLevelId.DIPLOMA_TOWARD
    ) {
      return <DiplomaLogo style={{ fill: color, width: "50px", height: "50px" }} />;
    } else if (
      qualification.value === qualificationLevelId.ECT ||
      qualification.value === qualificationLevelId.ECT_TOWARRD
    ) {
      return <EctLogo style={{ fill: color, width: "50px", height: "50px" }} />;
    } else {
      return <DiplomaLogo style={{ fill: color, width: "50px", height: "50px" }} />;
    }
  };
  const shouldDisableVerifyButton = !documentDate || !selectedQualificationId;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{ padding: "20px" }}
    >
      <DialogTitle>Qualification</DialogTitle>
      <DialogContent>
        <Chip
          color="error"
          sx={{
            mb: 1,
          }}
          label={document?.message}
          icon={<ErrorIcon sx={{ fill: "white" }} />}
        ></Chip>
        <Card
          sx={{
            padding: 2,
          }}
          variant="outlined"
        >
          <Row>
            {fileName?.endsWith("pdf") ? (
              <Worker workerUrl="/pdf.worker.min.js">
                <Viewer
                  fileUrl={
                    updatedFile ? URL.createObjectURL(updatedFile) : fileSrc
                  }
                  initialPage={0}
                />
              </Worker>
            ) : (
              <img
                src={updatedFile ? URL.createObjectURL(updatedFile) : fileSrc}
                alt={""}
                style={previewStyle}
              />
            )}
            {uploadingFile ? (
              <CircularProgress
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            ) : (
              <Typography
                sx={{
                  ml: 2,
                  fontSize: "12px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                {fileName}
              </Typography>
            )}
          </Row>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <FullSizedButton
            text={`Update File`}
            icon={<AddIcon />}
            type={"primaryContainer"}
            onClick={() => {
              fileInputRef.current.click();
            }}
          />
          <Divider />
          <Typography
            sx={{
              mt: 1,
              fontSize: "14px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Qualification
          </Typography>
          <Grid container spacing={2} padding={2}>
            {qualificationsList.map((item) => (
              <QualificationItem
                item={item}
                logo={getLogoComponent(item, item.selected? theme.palette.secondary.onSecondary: theme.palette.onSurfaceVariant.main)}
                onClick={() => {
                  const updatedQualificationsList = qualificationsList.map(
                    (qualification) => {
                      if (qualification.id === item.id) {
                        qualification.selected = !qualification.selected;
                      } else {
                        qualification.selected = false;
                      }
                      return qualification;
                    }
                  );
                  const selectedQualification = updatedQualificationsList.find(
                    (qualification) => qualification.selected
                  );
                  setQualificationsList(updatedQualificationsList);
                  setSelectedQualificationId(selectedQualification?.id);
                }}
              ></QualificationItem>
            ))}
          </Grid>
          <PaddingBox></PaddingBox>
          <Typography
            sx={{
              mb: 1,
              fontSize: "14px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Qualification/Enrollment Date
          </Typography>
          <DatePickerTextField
            value={documentDate}
            onChange={(e) => setDocumentDate(e.target.value)}
          ></DatePickerTextField>
          <Typography
            sx={{
              mt: 2,
              mb: 2,
              fontSize: "14px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Percentage Completed
          </Typography>
          <Row>
            <MileStoneChip
              label={"<30%"}
              selected={passedMilestones !== null && passedMilestones === false}
              onClick={() => {
                setPassedMilestones(false);
              }}
            ></MileStoneChip>
            <PaddingBox></PaddingBox>
            <MileStoneChip
              label={">30%"}
              selected={passedMilestones !== null && passedMilestones === true}
              onClick={() => {
                setPassedMilestones(true);
              }}
            ></MileStoneChip>
          </Row>
          <FullSizedButton
            text="Update"
            type={"primary"}
            disabled={shouldDisableVerifyButton}
            onClick={() => {
              verifyQualification();
            }}
          />
        </Card>
        <Card
          sx={{
            padding: 2,
            mt: 2,
          }}
          variant="outlined"
        >
          <Typography
            sx={{
              fontSize: "14px",
              textAlign: "left",
              fontWeight: "bold",
            }}
          >
            Failure Reason
          </Typography>
          <CommonTextField
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          ></CommonTextField>
          <FullSizedButton
            text="Reject"
            type={"tertiary"}
            onClick={() => {
              console.log("rejecting qualification");
              rejectQualification();
            }}
          />
        </Card>
      </DialogContent>
    </Dialog>
  );
}
function QualificationItem({ item, onClick, logo }) {
return(<Grid item xs={4}>
    <Card
      onClick={onClick}
      variant="outlined"
      sx={{
        padding: 1,
        cursor: "pointer",
        backgroundColor: item.selected
          ? theme.palette.secondary.main
          : theme.palette.background.default,
        color: item.selected
          ? theme.palette.secondary.onSecondary
          : theme.palette.text.primary,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logo}
        <Typography
          sx={{
            fontSize: "12px",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          {item.name}
        </Typography>
      </div>
    </Card>
  </Grid>)  
}
function MileStoneChip({ label, onClick, selected }) {
  return (
    <Chip
      variant={selected ? "contained" : "outlined"}
      label={label}
      sx={{
        borderRadius: 2,
        cursor: "pointer",
        backgroundColor: selected
          ? theme.palette.secondary.main
          : theme.palette.background.default,
        color: selected
          ? theme.palette.secondary.onSecondary
          : theme.palette.text.primary,
      }}
      onClick={onClick}
    ></Chip>
  );
}
function FullSizedButton({ text, onClick, type, icon, disabled }) {
  const colorTheme = {
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.onPrimary,
      "&:hover": {
        color: "white",
      },
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.onSecondary,
      "&:hover": {
        color: "white",
      },
    },
    primaryContainer: {
      backgroundColor: theme.palette.primaryContainer.main,
      color: theme.palette.primaryContainer.onPrimary,
      "&:hover": {
        color: "white",
      },
    },
    tertiary: {
      backgroundColor: theme.palette.tertiaryContainer.main,
      color: theme.palette.tertiaryContainer.onTertiary,
      "&:hover": {
        color: "white",
      },
    },
  };
  return (
    <Button
      disableElevation={true}
      variant="contained"
      startIcon={icon}
      disableRipple={true}
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: 2,
        marginY: 2,
        textTransform: "none",
        width: "100%",
        ...colorTheme[type],
      }}
    >
      {text}
    </Button>
  );
}
