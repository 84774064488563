 const RECENTLY_COMPLETED = 0,
  OPEN = 1,
  FILLED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  FINISHED = 11,
  IN_PROGRESS = 9,
  EXPIRED = 10,
  UNAVAILABLE_TO_WORK = 5,
  AVAILABLE_TO_WORK = 6

 const bonusList = ['0', '10', '20', '30', '40', '50']
 const SHIFT_STATES = {
  DRAFT: 'draft',
  AVAILABLE: 'available',
  EXPIRED: 'expired',
  FILLED: 'filled',
  CANCELLED: 'cancelled',
  CANCELLATE: 'cancelled_late',
  IN_PROGRESS: 'in_progress',
  NO_SHOW: 'no_show',
  FINISHED: 'finished',
  COMPLETED: 'completed'
}

 const ACCEPTED_TAB = 0,
  PENDING_TAB = 1,
  BLACKLISTED_TAB = 2

 const SHIFT_TABS = {
  OPEN: 0,
  FILLED: 1,
  FINISHED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  EXPIRED: 5
 }

 const availableFilter = [
  { id: 1, name: 'Available to work' },
  { id: 2, name: 'Unavailable to work' }
]

  const documentStatusFilter = [
  { id: null, name: 'All' },
  { id: 'success', name: 'Verified' },
  { id: 'verifying', name: 'Verifying' },
  { id: 'manual_required', name: 'Manual required' },
  { id: 'rejected', name: 'Rejected' },
  { id: 'failed', name: 'Failed' }
  ]

 const permissionList = ['owner', 'support', 'manager']

 const LOADING = 0;
 const ERROR = 1;

 const unreadMessageFilter = [
  { id: 1, name: 'All Shifts' },
  { id: 2, name: 'Only unread messages' }
]

 const assignmentGroupType = {
  all: 'all',
  quickcare: 'quickcare',
  org: 'org',
  centre: 'centre',
  invidualAssigned: 'individual-assigned',
  invidualRejected:'individual-rejected',
}

 const educatorStatusFilter = [
  { id: 0, name: 'Active', value: 'active' },
  { id: 1, name: 'Deactivated', value: 'deactivated' },
  { id: 2, name: 'Deleted', value: 'deleted' }
]

 const EDUCATOR_STATUSES = [
  'active',
  'deactivated',
  'deleted'
]

 const qualificationLevelId = {
  NONE: -2,
  ANY: -1,
  UNQUALIFIED: 0,
  CERT3: 1,
  CERT_TOWARD: 2,
  DIPLOMA: 3,
  DIPLOMA_TOWARD: 4,
  ECT: 5,
  ECT_TOWARRD: 6
}

module.exports = {
  RECENTLY_COMPLETED,
  OPEN,
  FILLED,
  COMPLETED,
  CANCELLED,
  SHIFT_STATES,
  EDUCATOR_STATUSES,
  IN_PROGRESS,
  EXPIRED,
  UNAVAILABLE_TO_WORK,
  AVAILABLE_TO_WORK,
  bonusList,
  ACCEPTED_TAB,
  PENDING_TAB,
  BLACKLISTED_TAB,
  SHIFT_TABS,
  documentStatusFilter,
  availableFilter,
  educatorStatusFilter,
  permissionList,
  FINISHED,
  LOADING,
  ERROR,
  unreadMessageFilter,
  assignmentGroupType,
  qualificationLevelId
}
